<template lang="pug">
.tools-list
  section.tools-list__item(v-for="tool in tools")
    .tools-list__content
      h3.tools-list__title {{ tool.name }}
      p.tools-list__text(
        v-html="$sanitize(tool.discription, { allowedTags: ['span'], allowedAttributes: { span: ['lang'] } })"
      )
      p.button.button_right.tools-list__button
        router-link.button__inner.button__inner_main(:to="tool.url") 使ってみる
    .tools-list__icon-area
      Icon.tools-list__icon(:type="tool.icon")
</template>

<script>
import tools from "@/assets/data/tools.json";
import Icon from "@/components/common/Icon";

export default {
  name: "ToolsList",
  components: {
    Icon,
  },
  data() {
    return {
      tools: [],
    };
  },
  created() {
    this.tools = tools;
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/import.scss";

.tools-list {
  @include media(tablet) {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    position: relative;
    @include media(tablet) {
      width: (100% - 4) / 3;
    }

    &:not(:first-of-type) {
      margin-top: (80 / 16) + em;

      @include media(tablet) {
        margin-top: 0;
      }
    }

    &:not(:nth-of-type(3n)) {
      @include media(tablet) {
        margin-right: 2%;
      }
    }

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: (144 / 16) + em;
    }
  }

  &__content {
    margin-top: 1em;
  }

  &__icon-area {
    color: color(text);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: (48 / 16) + em;
    background: linear-gradient(105deg, color(main), color(sub));
    width: 3em;
    height: 3em;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    font-size: 2em;
    font-weight: 900;
    line-height: 1.5;
  }

  &__button {
    margin-top: 1em;
  }
}
</style>