<template lang="pug">
.error404
  Hero(title="404 Not Found", lang="en")
  .layout.content
    .content__lead
      p.content__text
        | お探しのページは見つかりません。
        br
        | ページが削除されたか、<span lang="en">URL</span>が間違っている可能性があります。
      p.button.button_center.content__text
        router-link.button__inner.button__inner_main(to="/") トップページに戻る
</template>
<script>
import Hero from "@/components/common/Hero.vue";

export default {
  name: "error404",
  components: {
    Hero,
  },
};
</script>
