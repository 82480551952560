<template lang="pug">
.share
  button.share__toggle(
    @click="shareOpen = !shareOpen",
    aria-haspopup="true",
    aria-controls="share_buttons",
    :aria-expanded="shareOpen ? true : false"
  )
    transition(name="share__icon", :duration="500")
      Icon.share__icon(type="share", v-if="!shareOpen", key="close")
      Icon.share__icon(type="close", v-if="shareOpen", key="open")
    span.screen-reader-text シェアする
  transition(name="share", :duration="500")
    ul#share_buttons.share__list(v-show="shareOpen")
      li.share__item(v-for="(share, key) in shareList")
        a.share__link(
          :href="share.url",
          target="_blank",
          rel="noopener noreferrer",
          :class="'share__link_' + key",
          :title="share.name + 'でシェア'"
        )
          Icon(:type="share.icon")
          span.screen-reader-text {{ share.name }}
</template>
<script>
import Icon from "@/components/common/Icon";

export default {
  name: "Share",
  components: {
    Icon,
  },
  data() {
    return {
      shareOpen: false,
      shareList: {},
      item: {},
    };
  },
  beforeUpdate() {
    let pageUrl = this.siteUrl + this.$route.meta.canonical;
    let pageTitle =
      this.$route.name === "home"
        ? this.siteTitle
        : this.$route.name + " %7C " + this.siteTitle;
    pageTitle = pageTitle.replace(/ /g, "%20");
    this.shareList = {
      facebook: {
        url: "http://www.facebook.com/share.php?u=" + pageUrl,
        name: "Facebook",
        icon: "facebook",
      },
      twitter: {
        url:
          "https://twitter.com/share?url=" +
          pageUrl +
          "&via=nana_winter_web&related=nana_winter_web&text=" +
          pageTitle,
        name: "Twitter",
        icon: "twitter",
      },
      line: {
        url: "http://line.me/R/msg/text/?" + pageTitle + "%20" + pageUrl,
        name: "LINE",
        icon: "line",
      },
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/import.scss";

.share {
  position: fixed;
  bottom: 4em;
  right: 0.5em;
  z-index: 999;

  @include media(tablet) {
    bottom: 0.5em;
  }

  &-enter-active,
  &-leave-active {
    transition: all 0.5s ease-in-out;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
  }

  &-enter-to,
  &-leave {
    opacity: 1;
  }

  &__toggle {
    border-radius: 50%;
    width: 3.5em;
    height: 3.5em;
    display: block;
    background: color(base);
    box-shadow: 0 0 20px rgba(#000, 0.15);
    position: relative;

    @include hover($opacity: none) {
      box-shadow: 0 0 20px rgba(#000, 0.2);
    }

    &:focus {
      outline: 2px solid color(accent);
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &-enter-active,
    &-leave-active {
      transition: all 0.5s ease-in-out;
    }

    &-enter,
    &-leave-to {
      opacity: 0;
    }

    &-enter-to,
    &-leave {
      opacity: 1;
    }
  }

  &__list {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-100%);
    max-height: calc(100vh - 9em);
    overflow-y: auto;
    width: 100%;
    padding: 0.5em 0;

    @include media(tablet) {
      max-height: calc(100vh - 5em);
    }
  }

  &__item {
    &:not(:first-of-type) {
      margin-top: 0.25em;
    }
  }

  &__link {
    display: block;
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    box-shadow: 0 0 15px rgba(#000, 0.15);
    margin: 0 auto;

    &_facebook {
      background: #3b5998;
    }

    &_twitter {
      background: #1da1f2;
    }

    &_line {
      background: #00b900;
    }

    svg {
      vertical-align: -0.4em;
    }

    @include hover($opacity: none) {
      box-shadow: 0 0 15px rgba(#000, 0.25);
    }

    &:focus {
      outline: 1px solid color(accent);
    }
  }
}
</style>