<template lang="pug">
nav.main-nav
  h2.screen-reader-text メインナビゲーション
  ul.main-nav__list
    template(v-for="nav in navs")
      li.main-nav__item(v-if="nav.child === null")
        router-link.main-nav__button(:to="'/' + nav.url")
          Icon.main-nav__icon(:type="nav.icon")
          span.main-nav__text(lang="en") {{ nav.name }}
      li.main-nav__item(:class="nav.childrenOpen ? 'is-open' : false", v-else)
        button.main-nav__button(@click="nav.childrenOpen = !nav.childrenOpen")
          Icon.main-nav__icon(:type="nav.childrenOpen ? 'close' : 'bars'")
          span.main-nav__text(lang="en") {{ nav.name }}
        transition(name="main-nav__list_child", :duration="500")
          ul.main-nav__list.main-nav__list_child(v-show="nav.childrenOpen")
            li.main-nav__item(v-for="child in nav.children")
              router-link.main-nav__button(:to="'/' + nav.url + child.url")
                Icon.main-nav__icon(:type="child.icon")
                span.main-nav__text(lang="en") {{ child.name }}
    //- child-nav
//- nav
</template>
<script>
import Icon from "@/components/common/Icon";

export default {
  components: { Icon },
  data() {
    return {
      toolsOpen: false,
      navs: {
        home: {
          name: "Home",
          icon: "home",
          url: "",
          child: null,
        },
        palette: {
          name: "Palette",
          icon: "palette",
          url: "tools/palette/",
          child: null,
        },
        copitra: {
          name: "コピトラ",
          icon: "text",
          url: "tools/copitra/",
          child: null,
        },
      },
      //   tools: {
      //     name: "Tools",
      //     url: "tools/",
      //     childrenOpen: false,
      //     children: {
      //       palette: {
      //         name: "Palette",
      //         icon: "palette",
      //         url: "palette/"
      //       },
      //       sitemap: {
      //         name: "Sitemap",
      //         icon: "sitemap",
      //         url: "sitemap/"
      //       }
      //     }
      //   }
      // }
    };
  },
  methods: {
    childrenClose() {
      Object.keys(this.navs).forEach((nav) => {
        if (this.navs[nav].childrenOpen === true) {
          this.navs[nav].childrenOpen = false;
        }
      });
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/import.scss";

.main-nav {
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;

  @include dark {
    background: #333;

    @include media(tablet) {
      background: transparent;
    }
  }

  @include media(tablet) {
    font-size: 1rem;
    display: inline-block;
    background: transparent;
    position: static;
    width: auto;
  }

  &__list {
    display: flex;

    &_child {
      backdrop-filter: blur(5px);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translateY(-100%);
      background: rgba(#fff, 0.7);
      padding: 0 5%;
      box-sizing: border-box;

      @include dark {
        background: rgba(#333, 0.7);
        border-color: #fff;

        @include media(tablet) {
          background: transparent;
          backdrop-filter: none;
        }
      }

      @include media(tablet) {
        top: 100%;
        width: auto;
        padding: 0.5em 0 0 0;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        height: auto;
        background: transparent;
        border-top: 2px solid #333;
        margin-top: 0.5em;
        backdrop-filter: none;
      }

      &-enter-active,
      &-leave-active {
        transition: all 0.5s ease-in-out;
      }

      &-enter,
      &-leave-to {
        opacity: 0;
      }

      &-enter-to,
      &-leave {
        opacity: 1;
      }

      .main-nav__item {
        &:not(:first-of-type) {
          @include media(tablet) {
            margin-top: 0.5em;
          }
        }
        &:not(:last-of-type) {
          @include media(tablet) {
            margin-right: 0;
          }
        }
      }
    }
  }

  &__icon {
    text-align: center;

    @include media(tablet) {
      display: none;
    }
  }

  &__item {
    width: 50%;
    text-align: center;

    @include media(tablet) {
      position: relative;
      width: auto;
    }

    &:not(:last-of-type) {
      @include media(tablet) {
        margin-right: 2em;
      }
    }
  }

  &__button {
    display: block;
    padding: 0 0.25em 0.5em;
    text-decoration: none;
    height: 100%;
    background: transparent;
    width: 100%;
    font-weight: 600;
    color: #333;
    box-sizing: border-box;

    &.router-link-exact-active {
      color: color(main);

      @include media(tablet) {
        color: #333;
      }
    }

    @include media(tablet) {
      padding: 0;
    }

    @include dark {
      color: #fff;

      &.router-link-exact-active {
        color: color(main);

        @include media(tablet) {
          color: #fff;
        }
      }
    }

    @include hover;

    &:focus {
      outline: none;
      box-shadow: 0 0 6px color(accent), inset 0 0 3px color(accent);
    }
  }

  &__text {
    font-size: (10/16) + em;
    display: block;
    text-align: center;
    text-transform: uppercase;

    @include media(tablet) {
      font-size: 1em;
    }
  }
}
</style>
