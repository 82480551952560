<template lang="pug">
section.content
  titleMain(en="News", jp="お知らせ", tag="h2")
  dl.news-list.content__item
    .news-list__item(v-for="news in newsList")
      dt.news-list__date
        time(:datetime="news.date") {{ news.date.replace(/-/g, '/') }}
      dd.news-list__content(
        v-html="$sanitize(news.content, { allowedTags: ['span', 'br', 'a'], allowedAttributes: { span: ['lang', 'class'], a: ['href', 'class'] } })"
      )
</template>
<script>
import news from "@/assets/data/news.json";
import titleMain from "@/components/common/titleMain.vue";

export default {
  name: "News",
  components: {
    titleMain,
  },
  data() {
    return {
      newsList: [],
    };
  },
  created() {
    this.newsList = news;
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/import.scss";

.news-list {
  max-width: 512px;
  margin-left: auto;
  margin-right: auto;

  &__item {
    @include media(tablet) {
      display: flex;
      justify-content: space-between;
    }

    &:not(:first-of-type) {
      border-top: 1px solid #efefef;
      padding-top: 1em;
      margin-top: 1em;
    }
  }

  &__date {
    @include media(tablet) {
      width: 20%;
    }
  }

  &__content {
    @include media(tablet) {
      width: 75%;
    }
  }
}
</style>