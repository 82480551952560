import Vue from 'vue'

Vue.mixin(data)

import App from './App.vue'
import router from './router'
import store from './store'
import data from './mixins/data'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
