export default {
  methods: {
    toRGB(from, value) {
      let rgbLetter = new Array();

      if (from === "hsl") {
        value.h = parseInt(value.h);
        value.s = parseInt(value.s);
        value.l = parseInt(value.l);

        let max, min;

        let tempL = value.l < 50 ? value.l : 100 - value.l;

        max = Math.round(2.55 * (value.l + tempL * (value.s / 100)));
        min = Math.round(2.55 * (value.l - tempL * (value.s / 100)));

        var middle = function (h) {
          return Math.round(min + (max - min) * (h / 60));
        }

        if (value.h < 60) {
          rgbLetter.push(max);
          rgbLetter.push(middle(value.h));
          rgbLetter.push(min);
        } else if (value.h < 120) {
          rgbLetter.push(middle(120 - value.h));
          rgbLetter.push(max);
          rgbLetter.push(min);
        } else if (value.h < 180) {
          rgbLetter.push(min);
          rgbLetter.push(max);
          rgbLetter.push(middle(value.h - 120));
        } else if (value.h < 240) {
          rgbLetter.push(min);
          rgbLetter.push(middle(240 - value.h));
          rgbLetter.push(max);
        } else if (value.h < 300) {
          rgbLetter.push(middle(value.h - 240));
          rgbLetter.push(min);
          rgbLetter.push(max);
        } else {
          rgbLetter.push(max);
          rgbLetter.push(min);
          rgbLetter.push(middle(360 - value.h));
        }

        let rgb = {
          r: rgbLetter[0],
          g: rgbLetter[1],
          b: rgbLetter[2]
        };

        let hex = this.toHex(rgb);
        this.valueOutput(hex, rgb, value);
      }

      if (from === "hex") {
        let hexLetter = this.colors.hex.value.hex.value.toLowerCase().split("");

        // 計算処理
        if (this.colors.hex.value.hex.value.length === 3) {
          // 3文字なら6文字に変換
          let toRgbArray = new Array();
          for (var i = 0; i < hexLetter.length; i++) {
            toRgbArray.push(hexLetter[i], hexLetter[i]);
          }
          hexLetter = toRgbArray;
        }
        for (var j = 0; j < hexLetter.length; j++) {
          switch (hexLetter[j]) {
            case "a":
              hexLetter[j] = 10;
              break;
            case "b":
              hexLetter[j] = 11;
              break;
            case "c":
              hexLetter[j] = 12;
              break;
            case "d":
              hexLetter[j] = 13;
              break;
            case "e":
              hexLetter[j] = 14;
              break;
            case "f":
              hexLetter[j] = 15;
              break;
            default:
              hexLetter[j] = parseInt(hexLetter[j]);
          }
        }

        for (let x = 0; x < hexLetter.length; x = x + 2) {
          let a = hexLetter[x] * 16 + hexLetter[x + 1] * 1;
          rgbLetter.push(a);
        }

        let rgb = {
          r: rgbLetter[0],
          g: rgbLetter[1],
          b: rgbLetter[2]
        };
        let hsl = this.toHsl(rgb);
        this.valueOutput(value, rgb, hsl);
      }
    }
  }
}