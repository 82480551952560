export default {
  methods: {
    toHex(rgb) {
      let tempRgb = new Array(),
        hex = new Array();

      Object.keys(rgb).forEach(item => {
        tempRgb.push(rgb[item]);
      });

      tempRgb.reverse();

      tempRgb.forEach(item => {
        if (item < 16) {
          hex.push(item);
          hex.push(0);
        } else {
          while (item !== 0) {
            var temp = item % 16;
            hex.push(temp);
            item = (item - temp) / 16;
          }
        }
      });

      for (var i = 0; i < hex.length; i++) {
        switch (hex[i]) {
          case 10:
            hex[i] = "a";
            break;
          case 11:
            hex[i] = "b";
            break;
          case 12:
            hex[i] = "c";
            break;
          case 13:
            hex[i] = "d";
            break;
          case 14:
            hex[i] = "e";
            break;
          case 15:
            hex[i] = "f";
            break;
        }
      }

      if (hex[0] === hex[1] && hex[2] === hex[3] && hex[4] === hex[5]) {
        var shortHex = new Array();
        for (var j = 0; j < hex.length; j = j + 2) {
          shortHex.push(hex[j]);
        }
        hex = shortHex;
      }

      return hex
        .reverse()
        .toString()
        .replace(/,/g, "");
    }
  }
}