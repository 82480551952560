<template lang="pug">
.palette
  Hero(title="Palette", lang="en")
    p.hero__text
      | カラーコード、<span lang="en">RGB</span>、<span lang="en">HSL</span>の相互変換ツール。
      br
      | 値を入力すると自動で変換されます。
      br
      | カラーコードを入力する場合「#」を入力しても削除されます。
  .tool
    .tool__item
      .tool__options.settings.settings_row
        label.button-toggle.settings__item(for="changeOutputCase")
          input#changeOutputCase.button-toggle__input(
            type="checkbox",
            @click="changeOutputCase"
          )
          span.button-toggle__text 大文字で出力
        .button.settings__item
          button.button__inner.button__inner_main(
            type="button",
            @click="resetValue"
          ) リセット
      dl.field.tool__input
        .field__item(v-for="(color, key) in colors")
          dt.field__title
            template(v-if="Object.keys(color.value).length > 1") {{ color.name }}
            label(
              :for="'input' + key.charAt(0).toUpperCase() + key.slice(1)",
              v-else
            ) {{ color.name }}
          dd.field__content.form
            dl.form__item.form__item_row(v-for="(values, name) in color.value")
              dt.form__label
                label(
                  :for="'input' + name.charAt(0).toUpperCase() + name.slice(1)"
                )
                  template(v-if="key === 'hex'") #
                  template(v-else) {{ values.name }}
              dd.form__field.form__field_copy
                input.input-text(
                  type="text",
                  v-model="colors[key].value[name].value",
                  :name="name",
                  @input="checkValue(key + '.' + name)",
                  :id="'input' + name.charAt(0).toUpperCase() + name.slice(1)",
                  :aria-describedby="'error' + name.charAt(0).toUpperCase() + name.slice(1)"
                )
                CopyButton.form__copy(
                  :value="String(colors[key].value[name].value)",
                  :target="'copy' + name.charAt(0).toUpperCase() + name.slice(1)",
                  v-show="canCopy"
                )
              p.text-error(
                v-if="error[key][name]",
                role="alert",
                :id="'error' + name.charAt(0).toUpperCase() + name.slice(1)"
              ) {{ error[key][name] }}
      //- .field
    .tool__item
      svg.palette__result(
        xmlns="http://www.w3.org/2000/svg",
        viewBox="0 0 195.788 195.218"
      )
        path(
          d="M100,0c54.92,17.531,88.511,54.71,92.472,100s-43.9,81.687-92.472,91.5S17.834,142.992,0,100,45.08-17.531,100,0Z",
          transform="translate(3.086 2.622)",
          :fill="colors.hex.value.hex.value !== null ? '#' + colors.hex.value.hex.value : '#ff7f33'"
        )
</template>
<script>
import Hero from "@/components/common/Hero.vue";
import CopyButton from "@/components/common/CopyButton.vue";
import toHex from "@/mixins/tools/toHex";
import toHsl from "@/mixins/tools/toHsl";
import toRgb from "@/mixins/tools/toRgb";

export default {
  name: "palette",
  components: {
    Hero,
    CopyButton,
  },
  mixins: [toHex, toHsl, toRgb],
  data() {
    return {
      canCopy: false,
      upperCase: false,
      colors: {
        hex: {
          name: "カラーコード",
          value: {
            hex: {
              name: "HEX",
              value: null,
              copied: false,
            },
          },
        },
        rgb: {
          name: "RGB",
          value: {
            r: {
              name: "R",
              value: null,
              copied: false,
            },
            g: {
              name: "G",
              value: null,
              copied: false,
            },
            b: {
              name: "B",
              value: null,
              copied: false,
            },
            rgb: {
              name: "RGB",
              value: null,
              copied: false,
            },
            rgba: {
              name: "RGBa",
              value: null,
              copied: false,
            },
          },
        },
        hsl: {
          name: "HSL",
          value: {
            h: {
              name: "H",
              value: null,
              copied: false,
            },
            s: {
              name: "S",
              value: null,
              copied: false,
            },
            l: {
              name: "L",
              value: null,
              copied: false,
            },
            hsl: {
              name: "HSL",
              value: null,
              copied: false,
            },
          },
        },
      },
      error: {
        hex: {
          hex: null,
        },
        rgb: {
          r: null,
          g: null,
          b: null,
          rgb: null,
          rgba: null,
        },
        hsl: {
          h: null,
          s: null,
          l: null,
          hsl: null,
        },
      },
    };
  },
  methods: {
    copyValue(value, group, target) {
      if (value !== null) {
        document.addEventListener(
          "copy",
          (e) => {
            e.preventDefault();
            e.clipboardData.setData("text/plain", value);
          },
          { once: true }
        );
        document.execCommand("copy");
        this.colors[group].value[target].copied = true;

        setTimeout(() => {
          this.colors[group].value[target].copied = false;
        }, 1000);
      }
    },
    resetValue() {
      Object.keys(this.colors).forEach((color) => {
        Object.keys(this.colors[color].value).forEach((key) => {
          this.colors[color].value[key].value = null;
        });
      });
      Object.keys(this.error).forEach((error) => {
        Object.keys(this.error[error]).forEach((key) => {
          this.error[error][key] = null;
        });
      });
      this.canCopy = false;
    },
    changeOutputCase() {
      this.upperCase = !this.upperCase;
      if (this.colors.hex.value.hex.value !== null) {
        if (this.upperCase === true) {
          this.colors.hex.value.hex.value = this.colors.hex.value.hex.value.toUpperCase();
        } else {
          this.colors.hex.value.hex.value = this.colors.hex.value.hex.value.toLowerCase();
        }
      }
    },
    checkValue(value) {
      this.canCopy = false;
      let target = value.split(".");

      // hexエラーチェック
      if (target[0] === "hex" && this.colors.hex.value.hex.value !== null) {
        if (this.colors.hex.value.hex.value.indexOf("#") === 0) {
          this.colors.hex.value.hex.value = this.colors.hex.value.hex.value.slice(
            1
          );
        }

        if (
          (this.colors.hex.value.hex.value.match(/^[\da-fA-F]{3,6}$/) ===
            null ||
            this.colors.hex.value.hex.value.length % 3 !== 0 ||
            this.colors.hex.value.hex.value.length > 6) &&
          this.colors.hex.value.hex.value !== ""
        ) {
          this.canCopy = false;
          this.error.hex.hex = "入力値が正しくありません。";
        } else {
          this.error.hex.hex = null;

          if (this.colors.hex.value.hex.value !== "") {
            if (this.upperCase === true) {
              this.colors.hex.value.hex.value = this.colors.hex.value.hex.value.toUpperCase();
            } else {
              this.colors.hex.value.hex.value = this.colors.hex.value.hex.value.toLowerCase();
            }
            this.toRGB("hex", this.colors.hex.value.hex.value);
          }
        }
      }

      // rgbエラーチェック
      if (
        target[0] === "rgb" &&
        this.colors.rgb.value[target[1]].value !== null &&
        this.colors.rgb.value[target[1]].value !== ""
      ) {
        let rgb, input;

        if (target[1] === "rgb") {
          rgb = this.colors.rgb.value[target[1]].value.match(
            /^rgb\( ?(\d{1,3}) ?, ?(\d{1,3}) ?, ?(\d{1,3}) ?\)/
          );

          if (rgb !== null) {
            rgb.shift();
          }
        } else if (target[1] === "rgba") {
          rgb = this.colors.rgb.value[target[1]].value.match(
            /^rgba\( ?(\d{1,3}) ?, ?(\d{1,3}) ?, ?(\d{1,3}) ?, ?(\d{1,3}) ?\)/
          );

          if (rgb !== null) {
            rgb.shift();
          }
        } else {
          input = this.colors.rgb.value[target[1]].value.match(/^\d{1,3}$/);
        }

        if (
          ((target[1] === "r" || target[1] === "g" || target[1] === "b") &&
            (input === null ||
              this.colors.rgb.value[target[1]].value > 255 ||
              this.colors.rgb.value[target[1]].value < 0)) ||
          ((target[1] === "rgb" || target[1] === "rgba") &&
            (rgb === null ||
              rgb[0] < 0 ||
              rgb[0] > 255 ||
              rgb[1] < 0 ||
              rgb[1] > 255 ||
              rgb[2] < 0 ||
              rgb[2] > 255))
        ) {
          this.canCopy = false;
          this.error.rgb[target[1]] = "入力値が正しくありません。";
        } else {
          this.error.rgb[target[1]] = null;

          if (
            ((target[1] === "r" || target[1] === "g" || target[1] === "b") &&
              this.colors.rgb.value.r.value !== null &&
              this.colors.rgb.value.g.value !== null &&
              this.colors.rgb.value.b.value !== null &&
              this.error.rgb.r === null &&
              this.error.rgb.g === null &&
              this.error.rgb.b === null) ||
            (target[1] === "rgb" && this.error.rgb.rgb === null) ||
            (target[1] === "rgba" && this.error.rgb.rgba === null)
          ) {
            if (target[1] === "r" || target[1] === "g" || target[1] === "b") {
              rgb = {
                r: this.colors.rgb.value.r.value,
                g: this.colors.rgb.value.g.value,
                b: this.colors.rgb.value.b.value,
              };
            } else {
              rgb = {
                r: rgb[0],
                g: rgb[1],
                b: rgb[2],
              };
            }

            let hex = this.toHex(rgb);
            let hsl = this.toHsl(rgb);

            this.valueOutput(hex, rgb, hsl);
          }
        }
      }

      // hsl
      if (
        target[0] === "hsl" &&
        this.colors.hsl.value[target[1]].value !== null &&
        this.colors.hsl.value[target[1]].value !== ""
      ) {
        let hsl, input;
        if (target[1] === "hsl") {
          hsl = this.colors.hsl.value[target[1]].value.match(
            /^hsl\( ?(\d{1,3}) ?, ?(\d{1,3}) ?, ?(\d{1,3}) ?\)/
          );

          if (hsl !== null) {
            hsl.shift();
          }
        } else {
          input = this.colors.hsl.value[target[1]].value.match(/^\d{1,3}$/);
        }

        if (
          (target[1] === "h" && input === null) ||
          ((target[1] === "s" || target[1] === "l") &&
            (this.colors.hsl.value[target[1]].value > 100 ||
              this.colors.hsl.value[target[1]].value < 0)) ||
          (target[1] === "hsl" && hsl == null)
        ) {
          // エラーチェック
          this.canCopy = false;
          this.error.hsl[target[1]] = "入力値が正しくありません。";
        } else {
          this.error.hsl[target[1]] = null;

          if (
            ((target[1] === "h" || target[1] === "s" || target[1] === "l") &&
              this.colors.hsl.value.h.value !== null &&
              this.colors.hsl.value.s.value !== null &&
              this.colors.hsl.value.l.value !== null &&
              this.error.hsl.h === null &&
              this.error.hsl.s === null &&
              this.error.hsl.l === null) ||
            (target[1] === "hsl" &&
              this.colors.hsl.value.hsl.value !== null &&
              this.error.hsl.hsl === null)
          ) {
            if (target[1] === "h" || target[1] === "s" || target[1] === "l") {
              let hValue =
                this.colors.hsl.value.h.value >= 360
                  ? this.colors.hsl.value.h.value - 360
                  : this.colors.hsl.value.h.value;

              hsl = {
                h: hValue,
                s: this.colors.hsl.value.s.value,
                l: this.colors.hsl.value.l.value,
              };
            } else {
              this.colors.hsl.value.h.value = hsl[0];
              this.colors.hsl.value.s.value = hsl[1];
              this.colors.hsl.value.l.value = hsl[2];

              if (hsl[0] > 360) {
                hsl[0] = hsl[0] - 360;
              }

              hsl = {
                h: hsl[0],
                s: hsl[1],
                l: hsl[2],
              };
            }
            this.toRGB("hsl", hsl);
          }
        }
      }
    },
    valueOutput(hex, rgb, hsl) {
      this.error.hex.hex = null;

      Object.keys(this.error.rgb).forEach((item) => {
        this.error.rgb[item] = null;
      });

      Object.keys(this.error.hsl).forEach((item) => {
        this.error.hsl[item] = null;
      });

      this.colors.hex.value.hex.value = this.upperCase
        ? hex.toUpperCase()
        : hex.toLowerCase();

      Object.keys(rgb).forEach((item) => {
        this.colors.rgb.value[item].value = rgb[item];
      });
      this.colors.rgb.value.rgb.value =
        "rgb(" + rgb.r + ", " + rgb.g + ", " + rgb.b + ")";
      this.colors.rgb.value.rgba.value =
        "rgba(" + rgb.r + ", " + rgb.g + ", " + rgb.b + ", 1)";

      this.colors.hsl.value.h.value = hsl.h;
      this.colors.hsl.value.s.value = hsl.s;
      this.colors.hsl.value.l.value = hsl.l;
      this.colors.hsl.value.hsl.value =
        "hsl(" + hsl.h + ", " + hsl.s + ", " + hsl.l + ")";
      this.canCopy = true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/import.scss";
.form__label {
  width: 2.5em;
}

.palette {
  &__result {
    display: block;
    margin: 0 auto;
    width: 80%;

    @include media(tablet) {
      width: 50%;
    }
  }
}
</style>