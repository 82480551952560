<template lang="pug">
.hero
  .layout.hero__inner
    .hero__content
      p.hero__title
        | クリエイティブ活動を
        br
        | もっと便利に
      p.hero__text
        | 制作中に発生するちょっとした作業を楽にできるツールのサイト。
        br
        | 料理に欠かせないスパイスのように、
        br
        | クリエイティブ活動に欠かせないサイトになりたい。
    figure.hero__figure
      img.hero__img(
        src="/img/index/iphone.png",
        srcset="/img/index/iphone.png 1x, /img/index/iphone@2x.png 2x",
        alt=""
      )
    svg.shape.shape_left(
      xmlns="http://www.w3.org/2000/svg",
      xmlns:xlink="http://www.w3.org/1999/xlink",
      width="745.926",
      height="800.632",
      viewBox="0 0 745.926 800.632"
    )
      defs
        linearGradient#a(
          x1="0.429",
          y1="0.013",
          x2="0.083",
          y2="0.95",
          gradientUnits="objectBoundingBox"
        )
          stop(offset="0", stop-color="#ff7f33")
          stop(offset="1", stop-color="#ffd326")
      path(
        :d="path.from",
        transform="translate(-3961.035 -2012.891)",
        fill="url(#a)"
      )
        animate(
          attributeName="d",
          attributeType="XML",
          :values="path.from + ';' + path.to + ';' + path.from + ';'",
          dur="10s",
          repeatCount="indefinite",
          fill="freeze"
        )
    BackShape.shape.shape_top
</template>

<script>
import BackShape from "@/components/common/BackShape";

export default {
  name: "IndexHero",
  components: {
    BackShape,
  },
  data() {
    return {
      path: {
        from:
          "M4154.393,2043.555c-166.83,155.331-324.17,753.94-23.966,768.675s574.747-97.7,576.527-272.6S4321.223,1888.223,4154.393,2043.555Z",
        by:
          "M4154.393,2043.555c-166.83,130.331-324.17,753.94-23.966,759.675s574.747-97.7,568.527-272.6S4321.223,1950.223,4154.393,2043.555Z",
        to:
          "M4154.393,2043.555c-166.83,100.331-324.17,753.94-23.966,750.675s574.747-97.7,560.527-272.6S4321.223,2000.223,4154.393,2043.555Z",
      },
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/import.scss";

.shape {
  position: absolute;
  z-index: -1;
  &_top {
    max-width: 80%;
    height: auto;
    top: 0;
    right: 0;
    transform: translateY(-20%);

    @include media(tablet) {
      max-width: 80%;
    }

    @include media(pc) {
      transform: translateY(-45%);
    }

    @include media(pc_wide) {
      transform: none;
    }
  }

  &_left {
    top: 50%;
    left: 0;
    transform: translate(-10%, -50%);
    max-width: 80%;
    height: auto;
    max-height: 120vh;

    @include media(mobile_wide) {
      max-width: 60%;
    }

    @include media(tablet) {
      bottom: auto;
      top: 50%;
      transform: translate(-5%, -50%);
      max-width: 40%;
    }
  }
}

.hero {
  min-height: 100vh;
  display: flex;
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  padding: (46 / 16) + em 0;

  @include media(tablet) {
    padding: (62 / 16) + em 0;
  }

  &__content {
    @include media(tablet) {
      width: 55%;
    }

    @include media(pc) {
      width: 50%;
    }
  }

  &__inner {
    display: flex;
    align-items: center;
  }
  &__title {
    font-weight: 900;
    line-height: 1.6;
    font-size: (30 / 16) + em;
    @include media(tablet) {
      font-size: (40 / 16) + em;
    }
  }

  &__figure {
    display: none;

    @include media(tablet) {
      display: block;
      text-align: center;
      width: 45%;
    }

    @include media(pc) {
      width: 50%;
    }
  }

  &__img {
    filter: drop-shadow(0 0 10px rgba(#000, 0.2));

    @include media(tablet) {
      width: 60%;
    }

    @include media(pc) {
      width: 40%;
    }
  }
}
</style>