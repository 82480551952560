<template lang="pug">
.index
  IndexHero
  .layout
    IndexTools
    IndexNews
</template>

<script>
import IndexHero from "@/components/index/Hero.vue";
import IndexTools from "@/components/index/Tools.vue";
import IndexNews from "@/components/index/News.vue";

export default {
  name: "home",
  components: {
    IndexHero,
    IndexNews,
    IndexTools,
  },
};
</script>
