<template lang="pug">
.copy
  button.copy__button(@click="copyValue(value)", :aria-describedby="target")
    Icon(type="copy")
    span.screen-reader-text コピー
  p.tooltip.copy__popup(
    :id="target",
    v-show="copied",
    role="tooltip",
    :aria-hidden="copied ? false : true"
  ) コピーしました
</template>
<script>
import Icon from "@/components/common/Icon";

export default {
  name: "copyButton",
  components: {
    Icon,
  },
  props: {
    value: String,
    target: String,
  },
  data() {
    return {
      copied: false,
    };
  },
  methods: {
    copyValue(value) {
      if (value !== null) {
        document.addEventListener(
          "copy",
          (e) => {
            e.preventDefault();
            e.clipboardData.setData("text/plain", value);
          },
          { once: true }
        );
        document.execCommand("copy");
        this.copied = true;

        setTimeout(() => {
          this.copied = false;
        }, 1000);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/import.scss";

.copy {
  position: absolute;
  text-align: right;
  padding-right: 14px;

  &__button {
    background: transparent;
    color: color(light_gray);

    @include dark {
      color: #fff;
    }
  }

  &__popup {
    font-size: font(10);
    position: absolute;
    right: 0;
    bottom: 100%;
  }
}
</style>