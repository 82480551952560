<template lang="pug">
.copitra
  Hero(title="コピトラ")
    .hero__text
      p
        | 入力した単語を大文字、小文字、頭文字のみ大文字に変換します。
      p
        | 頭文字のみ大文字に変換する場合、空白文字（半角スペースや改行）は、すべて半角スペースに置き換えられます。
        br
        | 「<span lang="en"><span style="margin-right: 1em;">hello</span> world</span>」のように単語を全角スペースで区切った場合「<span lang="en"><span style="margin-right: 1em;">Hello</span> world</span>」のように変換されます。
  .tool
    .tool__item
      .tool__options.settings
        h2.screen-reader-text 変換設定
        p.settings__item(v-for="(name, key) in settings.outputCase")
          label.input-choice.input-choice_radio(:for="key")
            input.input-choice__input(
              :id="key",
              type="radio",
              name="outputCase",
              @click="changeOutputCase(key)",
              :checked="Object.keys(settings.outputCase)[0] === key ? true : false"
            )
            span.input-choice__text {{ name }}
        .button.settings__item
          button.button__inner.button__inner_main(
            type="button",
            @click="resetValue"
          ) 入力内容と変換結果をリセット
      //- / .settings
      .tool__input
        label(for="textBefore")
        textarea#textBefore.input-text.input-text_large(
          v-model="text.before",
          @keyup="textInput"
        )
      //- .tool__input
    .tool__item.tool__item_result.tool__item_has_icon-button
      h2.tool__title {{ text.after === '' ? '下に変換結果が表示されます' : '変換結果' }}
      output(for="textBefore") {{ text.after }}
      CopyButton.tool__icon-button(
        :value="text.after",
        target="copyText",
        v-show="canCopy"
      )
</template>
<script>
import Hero from "@/components/common/Hero.vue";
import CopyButton from "@/components/common/CopyButton.vue";

export default {
  name: "copitra",
  components: {
    Hero,
    CopyButton,
  },
  data() {
    return {
      canCopy: false,
      outputCase: "upperCase",
      settings: {
        outputCase: {
          upperCase: "すべて大文字に変換",
          capitalize: "頭文字のみ大文字、それ以外を小文字に変換",
          lowerCase: "すべて小文字に変換",
        },
      },
      text: {
        before: "",
        after: "",
      },
    };
  },
  methods: {
    textInput() {
      let words = "";
      let editedWords = "";
      switch (this.outputCase) {
        case "upperCase":
          this.text.after = this.text.before.toUpperCase();
          break;
        case "capitalize":
          words = this.text.before.split(/[ \t\f\r\n]/);
          words.filter((word) => {
            editedWords +=
              " " + word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
          });
          this.text.after = editedWords.trim();
          break;
        default:
          this.text.after = this.text.before.toLowerCase();
      }

      this.canCopy = this.text.after !== "" ? true : false;
    },
    resetValue() {
      this.text.before = "";
      this.text.after = "";
      this.canCopy = false;
    },
    changeOutputCase(value) {
      this.outputCase = value;
      this.textInput();
    },
  },
};
</script>
