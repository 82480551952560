<template lang="pug">
  .popup.popup_unsupport
    .popup__inner
      .popup__text
        slot
</template>
<script>
export default {
  name: "Popup"
};
</script>
<style lang="scss">
@import "@/assets/scss/style.scss";
.popup {
  position: absolute;
  height: 80vh;
  width: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  padding: 5%;
  z-index: 999;
  background: rgba(#fff, 0.8);

  @include dark {
    background: #333;
  }

  &__inner {
    width: 100%;
    height: 100%;
    overflow: auto;
  }
}
</style>
