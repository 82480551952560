<template lang="pug">
  svg(xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="1035" height="640" viewBox="0 0 1035 640")
    defs
      linearGradient(x2="1" y2="1" gradientUnits="objectBoundingBox")#b
        stop(offset="0" stop-color="#ff7f33")
        stop(offset="1" stop-color="#ffd326")
    path(:d="path.from" transform="translate(-3808 -2094)" fill="url(#b)")
      animate(attributeName="d"
        attributeType="XML"
        :values="path.from + ';' + path.to + ';' + path.from + ';'"
        dur="5s"
        repeatCount="indefinite"
        fill="freeze")
</template>
<script>
export default {
  name: "BackShape",
  data() {
    return {
      path: {
        from:
          "M3808,2094s112.842,347.514,445.082,399.38C4541.776,2529.1,4413.17,2688.029,4841.39,2734h0l1.61-640Z",
        by:
          "M3808,2094s112.842,345.514,438.082,390.38C4541.776,2515.1,4200.17,2688.029,4841.39,2734h0l1.61-640Z",
        to:
          "M3808,2094s112.842,340.514,430.082,380.38C4541.776,2500.1,4400.17,2650.029,4841.39,2734h0l1.61-640Z"
      }
    };
  }
};
</script>