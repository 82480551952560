<template lang="pug">
.hero
  .layout
    h1.hero__title(:lang="lang") {{ title }}
    slot
  BackShape.hero__back
</template>
<script>
import BackShape from "@/components/common/BackShape";

export default {
  name: "Hero",
  props: {
    title: String,
    lang: String,
  },
  components: {
    BackShape,
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/import.scss";

.hero {
  display: flex;
  align-items: center;
  min-height: 75vw;
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  padding: (46 / 16) + em 0;
  overflow: hidden;

  @include media(tablet) {
    padding: (62 / 16) + em 0;
    min-height: 50vw;
  }

  @include media(pc) {
    min-height: 25vw;
  }

  &__title {
    font-size: (30 / 16) + em;
    font-weight: 700;
    text-transform: uppercase;

    @include media(tablet) {
      font-size: (40 / 16) + em;
    }
  }

  &__back {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    max-width: 90%;
    max-height: 100%;
    height: auto;
    width: auto;
    transform: translateY(-30%);

    @include media(tablet) {
      transform: translateY(-50%);
    }

    @include media(pc) {
      transform: translateY(-30%);
    }
  }

  &__text {
    p {
      &:not(:first-child) {
        margin-top: 1em;
      }
    }
  }
}
</style>