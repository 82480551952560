import Vue from 'vue'
import VueRouter from 'vue-router'
import sanitizeHTML from 'sanitize-html'

// Pages
import Home from '../views/Home.vue'
import error404 from '../views/error404.vue'
// import privacy from '../views/Privacy.vue'
// import Terms from '../views/TermsOfServices.vue'

// tools
import palette from '../views/tools/Palette.vue'
import Copitra from '../views/tools/Copitra.vue'
// import Sitemap from '../views/tools/Sitemap.vue'

Vue.prototype.$sanitize = sanitizeHTML
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      description: '作業中に発生するちょっとした作業を楽にするツールのサイト。料理に欠かせないスパイスのように、クリエイティブ活動に欠かせないサイトになりたい。',
      canonical: '/'
    },
  },
  {
    path: '/tools/palette/',
    name: 'パレット',
    component: palette,
    meta: {
      title: 'Palette',
      description: 'カラーコードからRGB、RGBからカラーコードに相互変換できるツール。',
      canonical: '/tools/palette/',
    }
  },
  {
    path: '/tools/copitra/',
    name: 'コピトラ',
    component: Copitra,
    meta: {
      title: 'コピトラ',
      description: '単語を大文字、小文字、頭文字のみ大文字に変換するツール。',
      canonical: '/tools/copitra/',
    }
  },
  // {
  //   path: '/tools/sitemap/',
  //   name: 'サイトマップ',
  //   component: Sitemap,
  //   meta: {
  //     title: 'サイトマップ',
  //     description: '',
  //     canonical: '/tools/sitemap/',
  //   }
  // },
  // {
  //   path: '/privacy/',
  //   name: 'プライバシーポリシー',
  //   component: privacy,
  //   meta: {
  //     title: 'プライバシーポリシー',
  //     description: '',
  //     canonical: '/privacy/',
  //   }
  // },
  // {
  //   path: '/terms/',
  //   name: '利用規約',
  //   component: Terms,
  //   meta: {
  //     title: '利用規約',
  //     description: '',
  //     canonical: '/terms/',
  //   }
  // },
  {
    path: '*',
    name: 'ページが見つかりません',
    component: error404,
    meta: {
      title: 'ページが見つかりません',
      description: 'お探しのページは見つかりませんでした。ページが削除されたか、URLが間違っている可能性があります。'
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  let path = to.path;
  if (path.slice(-1) !== '/') {
    next(path + '/');
  } else {
    next();
  }
});

// ページタイトル変更
router.afterEach((to) => {
  if (to.meta) {
    const SITE_URL = "https://spice.u-web-nana.com";
    const TITLE_BASE = "クリエイティブ活動を便利にするツールのサイト SPICE";
    let secondTitle = to.meta.title + " | " + TITLE_BASE;

    document
      .querySelector("meta[property='og:url']")
      .setAttribute("content", SITE_URL + to.path);

    if (to.meta.canonical) {
      document
        .querySelector("link[rel='canonical']")
        .setAttribute("href", SITE_URL + to.meta.canonical);

      // document.querySelector('.share__link_facebook').setAttribute('href', "http://www.facebook.com/share.php?u=" + SITE_URL + to.meta.canonical)
      // document.querySelector('.share__link_twitter').setAttribute('href', "https://twitter.com/share?url=" + SITE_URL + to.meta.canonical + "&via=nana_winter_web related=nana_winter_web&text=" + to.meta.title ? secondTitle : TITLE_BASE);

      // document.querySelector('.share__link_line').setAttribute('href', "http://line.me/R/msg/text/?" + SITE_URL + to.meta.canonical + "%20" + to.meta.title ? secondTitle : TITLE_BASE);
    } else {
      document
        .querySelector("link[rel='canonical']")
        .setAttribute("href", SITE_URL + to.path);
    }

    if (to.path !== "/") {
      document
        .querySelector("meta[property='og:type']")
        .setAttribute("content", "article");
    }
    // ページタイトル
    if (to.meta.title) {
      document.title = secondTitle;
      document
        .querySelector("meta[property='og:title']")
        .setAttribute("content", secondTitle);
      document
        .querySelector("meta[name='twitter:title']")
        .setAttribute("content", secondTitle);
    } else {
      document.title = TITLE_BASE;
      document
        .querySelector("meta[property='og:title']")
        .setAttribute("content", TITLE_BASE);
      document
        .querySelector("meta[name='twitter:title']")
        .setAttribute("content", TITLE_BASE);
    }

    // 説明文
    if (to.meta.description) {
      document
        .querySelector("meta[name='description']")
        .setAttribute("content", to.meta.description);
      document
        .querySelector("meta[property='og:description']")
        .setAttribute("content", to.meta.description);
      document
        .querySelector("meta[name='twitter:description']")
        .setAttribute("content", to.meta.description);
    }
  }
})

export default router
