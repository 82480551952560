<template lang="pug">
footer.footer
  .footer__inner
    p.copyright
      small &copy;2019 <router-link to="/" lang="en" class="upper copyright__link">Spice</router-link> by <a href="https://twitter.com/nana_winter_web" target="_blank" rel="noopener noreferrer" lang="en" class="copyright__link">Nana Yamaguchi</a>
</template>

<script>
export default {
  name: "SiteFooter",
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/import.scss";

.page-enter-active,
.page-enter-active {
  & + .footer {
    opacity: 0;
  }
}

.footer {
  background: linear-gradient(155deg, color(main), color(sub));
  margin-top: (96 / 16) + em;
  padding: 0.5em 0;
  color: color(text);

  &__inner {
    width: width(main);
    max-width: width(max);
    margin: 0 auto;
  }
}

.copyright {
  font-size: (12 / 16) + em;
  text-align: center;
  font-weight: 700;

  &__link {
    color: currentColor;
    text-decoration: none;
    position: relative;

    &:before {
      content: "";
      width: 100%;
      height: 1px;
      background: currentColor;
      transition: $anime_nomal;
      display: block;
      position: absolute;
      top: 100%;
      left: 0;
    }

    @include hover($opacity: none) {
      &:before {
        width: 0;
      }
    }
  }
}
</style>