<template lang="pug">
  .title.title_main(:is="heddingTag")
    span(lang="en" aria-hidden="true").title__inner.title__inner_en {{en}}
    span.title__inner.title__inner_jp {{jp}}
</template>
<script>
export default {
  props: {
    en: String,
    jp: String,
    tag: String
  },
  computed: {
    heddingTag() {
      return this.tag;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/import.scss";

.title_main {
  text-align: center;
  line-height: 1.2;

  .title__inner {
    display: block;
    font-weight: 700;

    &_en {
      font-size: 2.5em;
      text-transform: uppercase;
    }

    &_jp {
      color: color(main);
      font-size: (14/16) + em;
    }
  }
}
</style>