<template lang="pug">
svg(
  xmlns="http://www.w3.org/2000/svg",
  width="24",
  height="24",
  viewBox="0 0 24 24",
  style="width: 1em; height: 1em; fill: currentcolor"
)
  path(d="M0,0H24V24H0Z", opacity="0")
  path(
    :d="icon[type].path",
    :transform="icon[type].transform ? icon[type].transform : 'translate(0)'"
  )
</template>
<script>
export default {
  name: "Icon",
  props: {
    type: String,
  },
  data() {
    return {
      icon: {
        facebook: {
          path:
            "M24,12A12,12,0,1,0,10.125,23.854V15.469H7.078V12h3.047V9.356c0-3.008,1.792-4.669,4.533-4.669a18.461,18.461,0,0,1,2.686.234V7.875H15.831A1.735,1.735,0,0,0,13.875,9.75V12H17.2l-.532,3.469h-2.8v8.385A12,12,0,0,0,24,12Z",
        },
        twitter: {
          path:
            "M82.548,117.952a13.915,13.915,0,0,0,14.01-14.01c0-.213,0-.425-.014-.636A10.019,10.019,0,0,0,99,100.756a9.829,9.829,0,0,1-2.828.775,4.941,4.941,0,0,0,2.165-2.724A9.868,9.868,0,0,1,95.21,100a4.929,4.929,0,0,0-8.391,4.491A13.98,13.98,0,0,1,76.67,99.349a4.928,4.928,0,0,0,1.524,6.573,4.887,4.887,0,0,1-2.235-.616v.062a4.926,4.926,0,0,0,3.95,4.827,4.916,4.916,0,0,1-2.223.084,4.93,4.93,0,0,0,4.6,3.42,9.88,9.88,0,0,1-6.115,2.112A10.025,10.025,0,0,1,75,115.74a13.94,13.94,0,0,0,7.548,2.208",
          transform: "translate(-75 -96.45)",
        },
        line: {
          path:
            "M-1624.667,702.865a.4.4,0,0,1-.322-.125.879.879,0,0,1-.061-.666l0-.026.176-1.008a2.465,2.465,0,0,0-.039-1.1c-.132-.329-.648-.5-1.057-.593a13.839,13.839,0,0,1-4-1.148,11.723,11.723,0,0,1-3.179-2.14,9.49,9.49,0,0,1-2.1-2.9,8.151,8.151,0,0,1-.757-3.425c0-5.368,5.383-9.735,12-9.735s12,4.367,12,9.735a7.638,7.638,0,0,1-.635,3.081,10.6,10.6,0,0,1-1.938,2.912,30.133,30.133,0,0,1-5.177,4.366,32.359,32.359,0,0,1-4.256,2.6A1.808,1.808,0,0,1-1624.667,702.865Zm-.414-15.655a.234.234,0,0,0-.233.234v5.218a.234.234,0,0,0,.233.233h.84a.237.237,0,0,0,.236-.233V689.5l2.4,3.238a.173.173,0,0,0,.06.058h.03a.222.222,0,0,0,.041.039h.824a.234.234,0,0,0,.234-.234v-5.218a.234.234,0,0,0-.234-.233v-.005h-.824a.234.234,0,0,0-.233.233v3.106l-2.409-3.238-.017-.022-.014-.014h-.934Zm-2.2-.066a.234.234,0,0,0-.233.233v5.218a.234.234,0,0,0,.233.233h.843a.234.234,0,0,0,.234-.233v-5.218a.234.234,0,0,0-.234-.233Zm-4.548,0a.234.234,0,0,0-.233.233v5.218a.238.238,0,0,0,.063.162.239.239,0,0,0,.162.063h3.37a.234.234,0,0,0,.234-.233v-.824a.234.234,0,0,0-.234-.233h-2.288v-4.152a.234.234,0,0,0-.233-.233Zm12.468,0a.232.232,0,0,0-.162.066.228.228,0,0,0-.066.162v5.218a.227.227,0,0,0,.066.162.231.231,0,0,0,.162.066h3.378a.234.234,0,0,0,.234-.233v-.821a.234.234,0,0,0-.234-.233h-2.3v-.893h2.3a.234.234,0,0,0,.234-.234v-.835a.234.234,0,0,0-.234-.234h-2.3v-.9h2.3a.234.234,0,0,0,.234-.233v-.83a.234.234,0,0,0-.234-.233Z",
          transform: "translate(1636 -679.43)",
        },
        share: {
          path:
            "M21.072,18.964a3.508,3.508,0,0,0-2.361.928l-8.59-5a3.944,3.944,0,0,0,.108-.843,3.944,3.944,0,0,0-.108-.843l8.494-4.952a3.606,3.606,0,1,0-1.157-2.639,3.944,3.944,0,0,0,.108.843L9.072,11.41a3.614,3.614,0,1,0,0,5.277L17.651,21.7a3.4,3.4,0,0,0-.1.783,3.518,3.518,0,1,0,3.518-3.518Z",
          transform: "translate(-1.69 -2)",
        },
        close: {
          path:
            "M29,7.417,26.583,5,17,14.583,7.417,5,5,7.417,14.583,17,5,26.583,7.417,29,17,19.417,26.583,29,29,26.583,19.417,17Z",
          transform: "translate(-5 -5)",
        },
        bars: {
          path: "M3,22H27V19.333H3Zm0-6.667H27V12.667H3ZM3,6V8.667H27V6Z",
          transform: "translate(-3 -2)",
        },
        copy: {
          path:
            "M17.273,1H4.182A2.188,2.188,0,0,0,2,3.182V18.455H4.182V3.182H17.273Zm3.273,4.364h-12A2.188,2.188,0,0,0,6.364,7.545V22.818A2.188,2.188,0,0,0,8.545,25h12a2.188,2.188,0,0,0,2.182-2.182V7.545A2.188,2.188,0,0,0,20.545,5.364Zm0,17.455h-12V7.545h12Z",
          transform: "translate(0 -1)",
        },
        home: {
          path: "M11.6,23.4V16.2h4.8v7.2h6V13.8H26L14,3,2,13.8H5.6v9.6Z",
          transform: "translate(-2 -1)",
        },
        palette: {
          path:
            "M9.577-20.766a11.936,11.936,0,0,0-9.333,9.3C-1.491-2.7,6.417,3.834,12.375,2.911a2.959,2.959,0,0,0,1.992-4.3A3.172,3.172,0,0,1,17.222-6h3.736A3.067,3.067,0,0,0,24-9.061,12.009,12.009,0,0,0,9.577-20.766ZM4.5-6A1.5,1.5,0,0,1,3-7.5,1.5,1.5,0,0,1,4.5-9,1.5,1.5,0,0,1,6-7.5,1.5,1.5,0,0,1,4.5-6ZM6-12a1.5,1.5,0,0,1-1.5-1.5A1.5,1.5,0,0,1,6-15a1.5,1.5,0,0,1,1.5,1.5A1.5,1.5,0,0,1,6-12Zm6-3a1.5,1.5,0,0,1-1.5-1.5A1.5,1.5,0,0,1,12-18a1.5,1.5,0,0,1,1.5,1.5A1.5,1.5,0,0,1,12-15Zm6,3a1.5,1.5,0,0,1-1.5-1.5A1.5,1.5,0,0,1,18-15a1.5,1.5,0,0,1,1.5,1.5A1.5,1.5,0,0,1,18-12Z",
          transform: "translate(0 21.002)",
        },
        text: {
          path:
            "M10.579,4V7.789h6.316V22.947h3.789V7.789H27V4ZM3,14.105H6.789v8.842h3.789V14.105h3.789V10.316H3Z",
          transform: "translate(-3 -1)",
        },
      },
    };
  },
};
</script>
