export default {
  methods: {
    toHsl(rgb) {
      rgb.r = parseInt(rgb.r);
      rgb.g = parseInt(rgb.g);
      rgb.b = parseInt(rgb.b);

      let max = Math.max(rgb.r, rgb.g, rgb.b),
        min = Math.min(rgb.r, rgb.g, rgb.b),
        hsl = new Array();

      // h
      if (max === rgb.r) {
        hsl.h =
          max - min === 0
            ? 60 * (rgb.g - rgb.b)
            : 60 * ((rgb.g - rgb.b) / (max - min));
      } else if (max === rgb.g) {
        hsl.h =
          max - min === 0
            ? 60 * (rgb.b - rgb.r) + 120
            : 60 * ((rgb.b - rgb.r) / (max - min)) + 120;
      } else if (max === rgb.b) {
        hsl.h =
          max - min === 0
            ? 60 * (rgb.r - rgb.g) + 240
            : 60 * ((rgb.r - rgb.g) / (max - min)) + 240;
      } else {
        hsl.h = 0;
      }

      if (hsl.h < 0) {
        hsl.h = hsl.h + 360;
      }

      hsl.h = Math.round(hsl.h);

      // s
      let cnt = (max + min) / 2;

      if ((max === 255 && min === 255) || (max === 0 && min === 0)) {
        hsl.s = 0;
      } else {
        if (cnt <= 127) {
          hsl.s = (max - min) / (max + min);
        } else {
          hsl.s = (max - min) / (510 - max - min);
        }
      }

      hsl.s = Math.round(hsl.s * 100);

      // l
      hsl.l = Math.round(((max + min) / 2 / 255) * 100);

      return hsl;
    }
  }
}