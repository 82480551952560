<template lang="pug">
#app(
  :class="[darkMode ? 'is-dark' : false, browser === 'unsupported' ? 'is-unsupported' : false]"
)
  Popup.popup__unsupported(v-if="browser === 'unsupported'")
    p
      | <span lang="en" class="upper">Spice</span>は現在お使いのブラウザは対応していません。
      br
      | <span lang="en">Google Chrome</span>、<span lang="en">Mozira Firefox</span>、<span lang="en">Safari</span>のいずれかのブラウザをお使いください。
  template(v-else)
    header.header
      .header__inner
        .logo-area(:is="logoTag")
          router-link.logo-area__link(to="/")
            transition(:duration="200", name="logo-area")
              img.logo-area__logo(
                src="/img/common/logo_white.svg",
                alt="SPICE",
                v-if="darkMode",
                key="logoWhite",
                aria-hidden="true"
              )
              img.logo-area__logo(
                src="/img/common/logo_black.svg",
                alt="SPICE",
                v-else,
                key="logoBlack",
                aria-hidden="true"
              )
            span.screen-reader-text(lang="en") Spice
        .header__contents
          .header__settings
            label.button-toggle(for="darkMode")
              input#darkMode.button-toggle__input(
                type="checkbox",
                @click="darkMode = !darkMode",
                :checked="darkMode"
              )
              span.button-toggle__text ダークモード
          MainNav(ref="mainNav")
    Share(ref="share")
    transition(name="page", :duration="300")
      router-view.page
    SiteFooter
</template>
<script>
import MainNav from "@/components/common/MainNav.vue";
import Popup from "@/components/common/Popup.vue";
import Share from "@/components/common/Share.vue";
import SiteFooter from "@/components/common/SiteFooter.vue";

export default {
  components: {
    MainNav,
    Popup,
    Share,
    SiteFooter,
  },
  data() {
    return {
      darkMode: false,
      logoTag: "p",
      browser: null,
    };
  },
  methods: {
    changeLogoTag() {
      if (this.$route.path === "/") {
        this.logoTag = "h1";
      } else {
        this.logoTag = "p";
      }
    },
  },
  created() {
    const ua = navigator.userAgent.toLowerCase();

    if (ua.indexOf("trident") !== -1 || ua.indexOf("edge") !== -1) {
      this.browser = "unsupported";
    }

    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      this.darkMode = true;
    }
  },
  mounted() {
    this.changeLogoTag();
  },
  watch: {
    $route() {
      this.$refs.mainNav.childrenClose();
      this.$refs.share.shareOpen = false;
      this.changeLogoTag();
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/style.scss";

noscript {
  background: linear-gradient(115deg, color(main), color(sub));
  width: 100%;
  height: 100vh;
  display: block;
  color: #fff;
  @include media(tablet) {
    text-align: center;
  }
}

.page {
  &-enter {
    opacity: 0;
  }
  &-enter-to {
    opacity: 1;
  }
  &-enter-active,
  &-leave-active {
    transition: all 0.3s linear;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
  &-leave {
    opacity: 1;
  }
  &-leave-to {
    opacity: 0;
  }
}

a,
button {
  &:focus {
    outline: none;
    box-shadow: 0 0 10px color(accent);
  }
}

#app {
  transition: $anime_nomal;
  color: #333;
  min-height: 100vh;

  &:not(:empty):not(.is-unsupported) {
    margin-bottom: (56/16) + em;

    @include media(tablet) {
      margin-bottom: 0;
    }
  }

  &.is-dark {
    background: #333;
    color: #fff;
  }

  &.is-unsupported {
    background: linear-gradient(color(main), color(sub));
    position: relative;
    overflow: hidden;
  }
}

.header {
  position: absolute;
  z-index: 997;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.5em 0;
  @include media(tablet) {
    position: fixed;
    padding: 1em 0;
  }

  &__inner {
    width: width(main);
    max-width: width(max);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__contents {
    @include media(tablet) {
      width: 85%;
      text-align: right;
      font-size: 0;
    }
  }

  &__settings {
    font-weight: 600;
    @include media(tablet) {
      font-size: 1rem;
      display: inline-block;
      margin-right: 5%;
    }
  }
}

.logo-area {
  width: 30%;
  @include media(tablet) {
    width: 15%;
  }

  &__link {
    position: relative;
    display: block;
    line-height: 1;

    @include hover;
  }

  &__logo {
    transition: $anime_nomal;
    width: 100%;
    height: auto;
  }

  &-enter-active,
  &-leave-active {
    transition: $anime_nomal;
  }

  &-leave-active {
    position: absolute;
    top: 0;
    left: 0;
  }

  &-enter {
    opacity: 0;
  }

  &-enter-to {
    opacity: 1;
  }

  &-leave {
    opacity: 1;
  }

  &-leave-to {
    opacity: 0;
  }
}

.text-error {
  color: color(accent_notice);
  font-weight: 700;
  width: 100%;

  @include dark {
    color: color(sub);
  }
}

.text-link {
  color: color(main);
  text-decoration: none;
  position: relative;

  &:after {
    width: 100%;
    content: "";
    height: 1px;
    background: color(main);
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    transition: $anime_nomal;
  }

  @include hover($opacity: none) {
    &:after {
      width: 0;
    }
  }
}

// form.

.input-text {
  border: 1px solid color(light_gray);
  border-radius: 500px;
  padding: 0.5em 1em;
  background: transparent;
  display: inline-block;
  width: 100%;
  box-sizing: border-box;

  @include dark {
    color: #fff;
    border-color: #fff;
  }

  &:focus {
    outline: none;
    border-color: color(main);
  }

  &::placeholder {
    color: color(light_gray);

    @include dark {
      color: #777777;
    }
  }

  &_large {
    border-radius: 16px;
    height: 15em;
  }

  .form__field_copy & {
    padding-right: 2.5em;
  }
}

.input-choice {
  $this: &;
  position: relative;
  display: inline-block;

  &__input {
    position: absolute;
    overflow: hidden;
    width: 1px;
    height: 1px;
    clip: rect(1px, 1px, 1px, 1px);

    &:focus + #{$this}__text {
      &:before {
        border-color: color(main);
      }
    }

    &:checked + #{$this}__text {
      &:after {
        opacity: 1;
      }
    }
  }

  &__text {
    padding-left: 1.5em;

    &:before,
    &:after {
      position: absolute;
      content: "";
      transition: $anime_nomal;
      display: block;
    }

    &:before {
      width: 1em;
      height: 1em;
      border: 1px solid color(light_gray);
      left: 0;
      top: 0.4375em;
      box-sizing: border-box;

      @include dark {
        border-color: #fff;
      }
    }

    &:after {
      opacity: 0;
    }
  }

  &_radio {
    #{$this}__text {
      &:before,
      &:after {
        border-radius: 50%;
      }

      &:after {
        background: color(main);
        top: (0.25em + 0.4375em);
        left: 0.25em;
        width: 0.5em;
        height: 0.5em;
      }
    }
  }

  &_checkbox {
    #{$this}__text {
      &:before {
        border-radius: 2px;
      }

      &:after {
        width: 1em;
        height: 0.5em;
        border-bottom: 2px solid color(main);
        border-left: 2px solid color(main);
        transform: rotate(-45deg);
        top: 0.4375em;
        left: 0;
      }
    }
  }
}

.tool {
  $this: &;

  width: width(main);
  max-width: width(max);
  margin: 0 auto;

  @include media(tablet) {
    display: flex;
    flex-wrap: wrap;
  }

  &__options {
    + #{$this}__input {
      margin-top: 1em;
    }
  }

  &__item {
    @include media(tablet) {
      width: 49%;
    }

    &:nth-of-type(odd) {
      @include media(tablet) {
        margin-right: 2%;
      }
    }

    &:not(:first-of-type) {
      margin-top: (80 / 16) + em;

      @include media(tablet) {
        margin-top: 0;
      }
    }

    &_has_icon-button {
      perspective: 1em;
      position: relative;
      box-sizing: border-box;
    }
  }

  &__title {
    font-size: 1.5rem;
  }

  &__icon-button {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.settings {
  $this: &;

  &__item {
    display: block;
    &:not(:first-child) {
      margin-top: 0.5em;
    }
  }

  &_row {
    display: flex;
    justify-content: space-between;
    #{$this}__item {
      &:not(:first-child) {
        margin-top: 0;
      }
    }
  }
}

/* main
----------------------------------- */
.content {
  &:not(:first-child) {
    margin-top: 5rem;

    @include media(tablet) {
      margin-top: 8rem;
    }
  }

  .hero + & {
    margin-top: 0;
  }

  &__lead {
    @include media(tablet) {
      text-align: center;
    }
  }

  &__text {
    &:not(:first-child) {
      margin-top: 1em;
    }
  }

  &__item {
    &:not(:first-child) {
      margin-top: 3rem;
    }
  }
}

.layout {
  width: width(main);
  max-width: width(max);
  margin: 0 auto;
}

.document {
  &__title {
    font-size: 1.5em;
    border-bottom: 2px solid color(main);
  }

  &__list {
    margin-left: 1.5em;
    margin-top: 1em;

    &_unorder {
      list-style: disc;
    }

    li {
      &:not(:first-of-type) {
        margin-top: 0.5em;
      }
    }
  }

  &__text {
    margin-top: 1em;
  }

  &__data {
    margin-top: 1em;
    text-align: right;
  }

  &__link {
    color: color(main);
    text-decoration: none;
    position: relative;

    &:after {
      width: 100%;
      content: "";
      height: 1px;
      background: color(main);
      display: block;
      position: absolute;
      top: 100%;
      left: 0;
      transition: $anime_nomal;
    }

    @include hover($opacity: none) {
      &:after {
        width: 0;
      }
    }
  }
}

/* button
----------------------------------- */

.button {
  // 中央寄せ
  &_center {
    text-align: center;
  }

  // 右寄せ
  &_right {
    text-align: right;
  }

  // 左寄せ
  &_left {
    text-align: left;
  }

  // button本体
  &__inner {
    display: inline-block;
    text-decoration: none;

    &_main {
      background: linear-gradient(105deg, color(main), color(sub));
      padding: 0.5em 1.5em;
      border-radius: 500px;
      color: color(text);
      position: relative;
      z-index: 2;
      line-height: 1.5;
      text-shadow: 0 0 6px rgba(#fff, 0.4);

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(105deg, color(sub), color(accent));
        opacity: 0;
        transition: $anime_nomal;
        border-radius: 500px;
        z-index: -1;
      }

      @include hover($opacity: none) {
        &:after {
          opacity: 1;
        }
      }
    }
  }

  &-toggle {
    &__text {
      position: relative;
      padding-right: 2.5em;

      &:before,
      &:after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 300px;
        transition: $anime_nomal;
      }
      &:before {
        right: 0;
        width: 2em;
        height: 1em;
        background: #eee;
      }

      &:after {
        right: 1.1em;
        width: 0.8em;
        height: 0.8em;
        background: #fff;
      }
    }

    &__input {
      position: absolute;
      width: 1px;
      height: 1px;
      clip: rect(1px, 1px, 1px, 1px);
      overflow: hidden;

      &:checked {
        & + .button-toggle__text {
          &:before {
            background: color(main);
          }

          &:after {
            right: 0.1em;
          }
        }
      }

      &:focus {
        & + .button-toggle__text {
          box-shadow: 0 0 10px color(accent);
        }
      }
    }
  } // toggle
} // .button

.tooltip {
  background: color(sub);
  padding: 0.2em 0.8em;
  margin-bottom: 0.4em;
  color: color(text);
  white-space: nowrap;
  border-radius: 500px;

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    right: 16px;
    border-top: 4px solid color(sub);
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
  }
}

.table {
  &__item {
    @include media(tablet) {
      display: flex;
    }

    &:not(:first-of-type) {
      margin-top: 1em;
    }
  }

  &__title {
    border-bottom: 1px solid color(main);
    padding-bottom: 1em;
    box-sizing: border-box;
    @include media(tablet) {
      width: 34%;
      padding-right: 1em;
    }
  }

  &__data {
    border-bottom: 1px solid color(light_gray);
    padding-bottom: 1em;
    @include media(tablet) {
      width: 66%;
    }
  }
}

.form {
  $this: &;

  &__item {
    &:not(:first-child) {
      margin-top: 16px;
    }

    &_row {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      #{$this}__label {
        text-align: center;
        padding-right: 0.5em;
        flex-shrink: 0;
      }

      #{$this}__field {
        flex-grow: 1;
      }
    }

    &_column {
      #{$this}__field {
        margin-top: 8px;
      }
    }
  }

  &__field {
    &_copy {
      position: relative;
    }
  }

  &__copy {
    position: absolute;
    right: 0.5em;
    top: 50%;
    transform: translateY(-50%);
  }
}

.field {
  &__title {
    font-weight: 700;
  }

  &__item {
    &:not(:first-of-type) {
      margin-top: 1em;
    }
  }

  &__content {
    position: relative;
  }
}
</style>
