<template lang="pug">
section.index-tools.content
  titleMain(en="Tools", jp="ツール一覧", tag="h2")
  ToolsList.content__item
</template>

<script>
// @ is an alias to /src
import titleMain from "@/components/common/titleMain.vue";
import ToolsList from "@/components/common/ToolsList.vue";

export default {
  name: "IndexTools",
  components: {
    titleMain,
    ToolsList,
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/import.scss";

.index-tools {
  &__item {
    @include media(tablet) {
      display: flex;
    }
    &:nth-of-type(even) {
      flex-direction: row-reverse;
    }

    &:not(:first-of-type) {
      margin-top: (80 / 16) + em;
    }
  }

  &__content {
    @include media(tablet) {
      width: 50%;
    }
  }

  &__title {
    font-size: 2em;
    font-weight: 900;
  }

  &__button {
    margin-top: 1em;
  }
}
</style>